import React from "react";
import LokalitaTemplate from "../../templates/LokalitaTemplate";
import { StaticQuery, graphql, Link } from "gatsby"
import RegionyTemplate from "../../templates/RegionyTemplate";
import UniversalTemplate from "../../templates/UniversalTemplate";
import CenovaKalkulacka from "../../components/CenovaKalkulacka";



const faqData = [
  {
    otazka: "Co všechno obsahuje cena?",
    odpoved: `Ta obsahuje vyzvednutí zesnulého kdekoliv po Brně a blízkého okolí, převezení do krematoria, samotný žeh a následné uložení do dočasné urny. Doručení urny do vlastních rukou kamkoliv po Brně. V ceně je také administrativa spojená s vyřízením <a href="/slovnik-pojmu/umrtni-list/">úmrtního listu</a> a možnost obracet se na naši zákaznickou linku 24 hodin 7 dní v týdnu.`
  },
  {
    otazka: "Budu platit něco navíc?",
    odpoved: `Poplatky navíc budete platit pouze v případě, kdy by k vyzvednutí zesnulého došlo po více jak 48 hodinách od úmrtí. Ze zákona si potom <a href="/slovnik-pojmu/vyzvednuti-zesnuleho/">nemocnice</a> nebo jiné instituce mají nárok účtovat s tím spojené náklady. Poplatek se sice do finální ceny promítne, ale nám nezůstane. Pošleme ho do dané nemocnice nebo instituce za vás. Podobný poplatek může vystavit krematorium pokud měl pozůstalý více než 100kg a musela by se použít nadměrná rakev.<br/><br/>U nás se s poplatkem setkáte pouze tehdy, když pro zesnulého jedeme mimo dané město. To se pak počítá podle <a href="/slovnik-pojmu/cena-za-dopravu/">najetých kilometrů</a>.`
  },
  {
    otazka: "Kdy a jak se platí?",
    odpoved: "Platba zálohy probíhá vždy předem. Je možné ji provést buď bankovním převodem nebo online přes platební bránu. Fakturu s údaji o platbě obdržíte po vytvoření závazné objednávky. Po pohřbu zákazník obdrží druhou fakturu za zbytek částky která není obsažená v záloze. Například v případě, že využijete i některých dalších služeb které nabízíme: jiná urna, květiny a podobně. Moc rádi vám v případě zájmu zařídíme i platbu na splátky skrz naše partnery. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Je možné platit na splátky?",
    odpoved: "Ano, u nás můžete platit na splátky. Prostřednictvím našich partnerů jsme vám schopni splácení faktury zařídit do 24 hodin. Pro více informací se neváhejte obrátit na naši pomocnou linku 315 558 136."
  },
  {
    otazka: "Jaké dokumenty budu potřebovat?",
    odpoved: `Pokud je zesnulý doma, budete k předání pohřební službě potřebovat <a href="/slovnik-pojmu/list-o-prohlidce-tela/">ohledací list</a>, který vystaví Zdravotnická záchranná služba nebo <a href="/slovnik-pojmu/koroner/">koroner</a>. Pokud je v nemocnici nebo pečovatelském domě, postarají se o vystavení dokumentu i předání pohřební službě příslušní zaměstnanci. Dále od vás budeme potřebovat <span>kopii vašeho občanského průkazu</span>, občanský průkaz zesnulé/ho a <span>plnou moc</span>, kterou s vámi podepíšeme online. Ta se musí vystavit proto, abychom mohli manipulovat s tělem zesnulého.`
  },
  {
    otazka: "Poskytujete služby po celé ČR?",
    odpoved: `V současné chvíli poskytujeme pohřební služby téměř po celé ČR. Konkrétní město si můžete zkontrolovat v naší sekci <a href="/pobocky/">pobočky</a>. Pokud vám jde o lokalitu jinou, i tak se na nás neváhejte obrátit. V případě potřeby jsme schopni zajet kamkoliv.`
  },
  {
    otazka: "Jak si můžu být jistý/á, že nedojde k záměně popela?",
    odpoved: `Krematorium je ze zákona o pohřebnictví povinno do <a href="/rakve/">rakve</a> vkládat kovový plíšek s označením. Ten není během kremace poškozen a následně je i s popelem vložen do stejně označené <a href="/poradna/kremacni-popel/">urny</a>. Krematorium vede o celém procesu podrobnou evidenci, takže záměna je vyloučena.`
  },
];


export const query = graphql`
  query {
    brnoVenkov: file(relativePath: { eq: "brno-venkov.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 350) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`;

const BrnoVenkov = ({data}) => {


  return(
    <UniversalTemplate 
      h1="Pohřební služba Brno Venkov"
      title={"Pohřební služba Brno Venkov: Empatická & Dostupné ceny"}
      desc="Jsme spolehlivá pohřební služba v Brně-venkov, do naší dojezdové vzdálenosti patří mimo jiné Ivančice, Kuřim, Pohořelice..."
      perex={<>Pokud váš blízký zemřel v nemocnici v Ivančicích, Tišnově, v Domově Matky Rosy, Domě Léčby Bolesti S Hospicem sv. Josefa v Rajhradě nebo u vás doma v Rosicích, Šlapanicích nebo kdekoliv jinde po Brně-venkov, můžete se obrátit na naši pohřební službu. Jsme vám k dispozici na telefonu 24 hodin 7 dní v týdnu.</>}
      imgFixed={data.brnoVenkov.childImageSharp.fixed}
      imgAlt="Brno Venkov"
      breadscrumStranky={[
        {name: "Domů", url: "/"},
        {name: "Pohřební služba Brno", url: "/pohrebni-sluzba-brno/"},
        {name: "Pohřební služba Brno Venkov", url: "/pohrebni-sluzba-brno/venkov/"}
      ]}
      faqData={faqData}
      mesto="Brno"
      seVsimText={"Chápeme, že smrt blízkého je situace, kterou neplánujete. Zavolejte nám a my jsme připraveni vaši situaci okamžitě řešit. Se vším vám pomůžeme a vytvoříme pro vás a vaše blízké to nejosobnější poslední rozloučení v Brně-venkov a okolí."}
      
      content={<>
        <p>Jsme spolehlivá <Link to="/pohrebni-sluzba-brno/">pohřební služba v Brně</Link>, která se postará o důstojné poslední rozloučení s vaším blízkým. Pomůžeme vám vše vyřídit přes internet a z bezpečí vašeho domova. Můžete se na nás obrátit ať máte zájem o kremaci bez obřadu nebo rozloučení v jedné z brněnských smutečních síní. Obřad je možné zajistit například ve <Link to="/obradni-sine/velka-obradni-sin-krematoria-mesta-brna/">Velké obřadní síni krematoria Brna</Link> nebo v <Link to="/obradni-sine/smutecni-sin-zbysov/">obřadní síni Zbýšov</Link> v západní části Brna-venkov.</p>
        <p>Pokud váš blízký zemřel některé z nemocnic v Brně-venkov, kontaktujte nás a my už se s danou nemocnicí domluvíme na jeho vyzvednutí. V případě <Link to="/poradna/umrti-blizke-osoby-pruvodce/">úmrtí doma</Link> se můžete obrátit na naši nonstop linku a do několika málo hodin k vám, za cenu ujetých kilometrů, přijedeme, ať bydlíte v Tišnově, Rosicích nebo Ivančicích.</p>

        <CenovaKalkulacka compact />

        <h2>Jaký je postup?</h2>
        
        <h3>Nezávazně si zavoláme a probereme vaši situaci</h3>
        <p>Chápeme, že smrt blízkého je událost, kterou neplánujete. Na naší telefonní lince jsme vždy připraveni vaši situaci okamžitě řešit, ať se jedná o noc nebo den, všední den či svátek. Probereme společně vaši představu a najdeme ideální řešení pro vás a vaši rodinu, ať už se rozhodnete pro kremaci bez obřadu nebo obřad v některé z brněnských obřadních síní. Na telefonu jsme pro vás každý den, 24/7.</p>
        
        <h3>Pohřeb s námi naplánujete z bezpečí domova</h3>
        <p>Na plánování budete mít dostatek času a nemusíte nikam chodit. Vše s vámi vyřešíme přes telefon a e-mail. Bez zbytečného stresu a tlaku si můžete rozmyslet detaily obřadu od <Link to="/poradna/smutecni-kytice-pohreb/">květinové výzdoby</Link> přes hudební doprovod až po ozdobný obal na urnu nebo <Link to="/slovnik-pojmu/pamatecni-predmety/">památeční předmět</Link> vyrobený z popela. My jsme vám celou dobu k dispozici a připraveni pomoci.</p>
        
        <h3>O vše ostatní se už postaráme my</h3>
        <p>Ať už se rozhodnete pro jakýkoli druh pohřbu, naší prioritou je, umožnit vám důstojné poslední rozloučení podle vašich představ. My jsme tu, abychom vyřešili komunikaci s matrikou, oblečení a upravení zesnulého, přípravu obřadní síně a další náležitosti.</p>

        <h2>V čem jsme jiní než ostatní pohřební služby v Brně-venkov?</h2>

        <h3>Vše vyřešíme přes internet</h3>
        <p>Ať váš blízký zemřel doma, ve Fakultní nemocnici Brno, v Domově pro seniory Mikuláškovo náměstí, v Bohunicích nebo kdekoli jinde v Brně, nemusíte nikam chodit. Vše vyřídíte z bezpečí domova, kde můžete detaily posledního rozloučení v klidu vybrat a promyslet s rodinou.</p>
        
        <h3>Vždy za transparentní ceny</h3>
        <p>Cena, na které se domluvíme, je vždy konečná. Obsahuje kompletně vše, co budete potřebovat, kromě kilometrů ujeté mimo Brno. I tuto cenu jsme ale schopni vám říct dopředu na základě toho, kam v Brně-venkov pro vašeho blízkého pojedeme.</p>
        
        <h3>Poskytneme vám speciální péči</h3>
        <p>Chápeme, že úmrtí blízké osoby je nečekaná událost. Proto jsme připraveni ji s vámi řešit v čase, který vyhovuje vám a vaší rodině. Ať už to bude v 10 hodin večer, až půjdou děti spát, nebo během sobotního odpoledne, kdy nebudete rušeni pracovními hovory. Ozvat se nám můžete 24 hodin 7 dní v týdnu.</p>
        
        <p>Jako další vás mohou zajímat lokality:</p>
        <ul>
          <li><Link to="/pohrebni-sluzba-brno/lisen/">Pohřební služba Brno Líšeň</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/koliste/">Pohřební služba Brno Koliště</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/jihlavska/">Pohřební služba Brno Jihlavská</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/bohunice/">Pohřební služba Brno Bohunice</Link></li>
          <li><Link to="/pohrebni-sluzba-brno/videnska/">Pohřební služba Brno Vídeňská</Link></li>
        </ul>
      </>}
    />
  );
}


export default BrnoVenkov;
